if(!Element.prototype.closest)
{
  const closestPolyfill = (el, s) =>
  {
    do
    {
      if(el.matches(s))
        return el

      el = el.parentElement || el.parentNode
    } while(el !== null && el.nodeType === 1)

    return null
  }

  Element.prototype.closest = (s) =>
  {
    if(!document.documentElement.contains(this))
      return null

    return closestPolyfill(this, s)
  }
}
